type RatingResponse = {
	success: boolean;
	message: string;
	data?: {
		count: number;
		stars: string[];
		text: string;
		value: number;
	};
};

const formTarget = '/kegelspiele/bewerten';
const formMethod = 'POST';
const ratingElement: HTMLElement | null =
	document.querySelector('#current-rating');
const ratingTextElement: HTMLElement | null =
	document.querySelector('#rating-text');
let previousText = ratingTextElement?.textContent ?? '';

const addStarToElement = (element: HTMLElement, symbol: string) => {
	const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
	svg.setAttribute('width', '24');
	svg.setAttribute('height', '24');
	svg.setAttribute('aria-hidden', 'true');
	element.append(svg);
	const use = document.createElementNS('http://www.w3.org/2000/svg', 'use');
	use.setAttributeNS(
		'http://www.w3.org/1999/xlink',
		'xlink:href',
		`#${symbol}-star`,
	);
	svg.append(use);
};

const setStatus = (text: string) => {
	const formElement: HTMLFormElement | null =
		document.querySelector('#rating-form');
	if (formElement) {
		formElement.remove();
	}

	if (ratingElement) {
		ratingElement.style.display = 'inline-flex';
	}

	if (ratingTextElement) {
		ratingTextElement.textContent = text;
		globalThis.setTimeout(() => {
			ratingTextElement.textContent = previousText;
		}, 3000);
	}
};

const updateRating = (stars: string[], text: string) => {
	if (ratingElement) {
		ratingElement.innerHTML = '';
		for (const symbol of stars) {
			addStarToElement(ratingElement, symbol);
		}
	}

	previousText = text;
};

const handleRating = async (value: number) => {
	try {
		const slug = new URL(location.href).pathname.split('/').pop();
		const formData = new FormData();
		formData.append('rating', String(value));
		formData.append('slug', slug ?? '');
		const response = await fetch(formTarget, {
			method: formMethod,
			body: formData,
		});
		if (response.status === 204) {
			setStatus('Du hast bereits abgestimmt.');
		} else {
			const {success, data} = (await response.json()) as RatingResponse;
			if (success && data) {
				setStatus('Gespeichert.');
				updateRating(data.stars, data.text);
			} else {
				setStatus('Versuche es später nochmal!');
			}
		}
	} catch {
		setStatus('Versuche es später nochmal!');
	}
};

const handleLabelUnhover = () => {
	if (ratingTextElement) {
		ratingTextElement.textContent = previousText;
	}
};

if (navigator.onLine && ratingElement && ratingTextElement) {
	const form = document.createElement('form');
	form.method = formMethod;
	form.action = formTarget;
	form.id = 'rating-form';
	form.classList.add('rating-stars');
	for (let index = 1; index <= 5; index++) {
		const text = `${index} Stern${index === 1 ? '' : 'e'}`;
		// Add input.
		const input = document.createElement('input');
		input.type = 'radio';
		input.name = 'rating';
		input.id = `rating-${index}`;
		input.value = String(index);
		input.title = text;
		input.addEventListener('change', async () => {
			await handleRating(index);
		});
		form.append(input);
		// Add label.
		const label = document.createElement('label');
		label.htmlFor = `rating-${index}`;
		label.addEventListener('mouseover', () => {
			ratingTextElement.textContent = text;
		});
		label.addEventListener('mouseout', handleLabelUnhover);
		form.append(label);
		// Add accessible text.
		const wrapper = document.createElement('span');
		wrapper.setAttribute('role', 'img');
		wrapper.setAttribute('aria-label', text);
		label.append(wrapper);
		// Add images.
		for (const symbol of ['empty', 'full']) {
			addStarToElement(wrapper, symbol);
		}
	}

	ratingElement.after(form);
	ratingElement.parentElement?.classList.add('with-form');
}
